/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"

import Helmet from "react-helmet"
import Header from "./header"
import "bootstrap/dist/css/bootstrap.min.css"
import "../../static/styles/main.scss"
import "../../static/theme/css/theme-blue.css"
import "../../static/theme/css/icons.min.css"
import "../../static/styles/custom.scss"
import logo from "../../static/images/dptlogo.png"
import iconTwitter from "../../static/images/icon-twitter.svg"
import iconLinkedin from "../../static/images/icon-linkedin.svg"
import iconGitHub from "../../static/images/icon-github.svg"
import iconInstagram from "../../static/images/icon-instagram.svg"

const Head = ({ children }) => (
  <>
    <Helmet>
      <link rel="icon" href="/favicon.ico" />
      <link rel="stylesheet" id="ebor-heading-font-css" href="https://fonts.googleapis.com/css?family=Raleway%3A100%2C400%2C300%2C500%2C600%2C700&amp;ver=5.4.2" type="text/css" media="all"></link>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      {/* <script
        src="https://cdnjs.cloudflare.com/ajax/libs/holder/2.9.7/holder.min.js"
        integrity="sha256-CPLvnJ0LSBm+lJAUh4bBMpJ1lUa3QsTfdgCAUHyBv2w="
        crossOrigin="anonymous"
      /> */}


      {/* <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossorigin="anonymous" /> */}
 {/*
      <link
        rel="stylesheet"
        type="text/css"
        href="//cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.1.0/cookieconsent.min.css"
      />
      <script src="//cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.1.0/cookieconsent.min.js" />
      <script>
        {`
            window.addEventListener("load", function(){
            window.cookieconsent.initialise({
            "palette": {
                "popup": {
                "background": "#252e39"
                },
                "button": {
                "background": "#14a7d0"
                }
            },
            "content": {
                "href": "https://www.iubenda.com/privacy-policy/20689920"
            }
            })});`}
      </script> */}
      {/* <script type="text/javascript">{`(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);`}</script> */}
      {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
{/* 
      {process.env.NODE_ENV == 'production' ? ( 
                        <>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=UA-132869296-1"
      />
      <script>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-132869296-1');
        `}
      </script>

                        </>
                      ) : null} */}

      {/* <!-- Facebook Pixel Code --> 
    <script>{`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '310282202958577');
        fbq('track', 'PageView');
        `}
    </script>
    <noscript>{`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=310282202958577&ev=PageView&noscript=1" />`}</noscript>*/}
      {/* <!-- End Facebook Pixel Code --> */}

      <link href="https://calendly.com/assets/external/widget.css" rel="stylesheet" />
        
<script src="https://calendly.com/assets/external/widget.js" type="text/javascript"></script>


{/* 
<link
            href="/css/bootstrap.css"
            rel="stylesheet"
            type="text/css"
            media="all"
              /> */}
	          <link rel="stylesheet"
            type="text/css"
            media="all"
            href="/fonts/themify-icons.css"
            />
          {/* <link
            href="/css/themify-icons.css"
            rel="stylesheet"
            type="text/css"
            media="all"
          /> */}
          
          <link
            href="/css/flexslider.css"
            rel="stylesheet"
            type="text/css"
            media="all"
          />
          <link
            href="/css/lightbox.min.css"
            rel="stylesheet"
            type="text/css"
            media="all"
          />
          <link
            href="/css/ytplayer.css"
            rel="stylesheet"
            type="text/css"
            media="all"
          />
          <link
            href="/css/theme.css"
            rel="stylesheet"
            type="text/css"
            media="all"
          />
          <link
            href="/css/custom.css"
            rel="stylesheet"
            type="text/css"
            media="all"
          />
          <link
            href="//fonts.googleapis.com/css?family=Lato:300,400%7CRaleway:100,400,300,500,600,700%7COpen+Sans:400,500,600"
            rel="stylesheet"
            type="text/css"
          />
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n    #icons .col-md-4{\n        max-height: 136px;\n    }\n",
            }}
          />
    </Helmet>
  </>
)


const Footer = ({ data }) => (
  <>
    <footer>
      <div className={"container footer-bar"}>
        <div className={"row"}>
          <div className={"col-md-5"}>
            <div className={"widget__item"}>
              <div className={"logo"}>
                <Link to="/" title={data.site.siteMetadata.title}>
                  {/* <img className="img-fluid" alt={"Logo"} src={logo} /> */}
                  {data.site.siteMetadata.title}
                </Link>
              </div>

              <div className={"about"}>
                <p>{data.site.siteMetadata.footerDescription}</p>
              </div>

              <div id="text-5" className="widget widget_text">
                {/* <h6 className="title">UVISION</h6><hr /> */}
                <div className="textwidget">
                  {/* <p>&nbsp;</p> */}
                  <p>
                   {/*  UVISION
                    <br />
                    1900 Campus Commons Drive, Suite 100, Reston, VA 20191, USA
                   <a href="tel:+17037292305">+703.729.2305</a><br /> */}
                  </p> 
            {/*       <p><br/>
                    <a href="mailto:engage%40u%76%69s%69on.%63o">
                      engage@theleanfounder.com
                    </a>
                    <br />
                    <a
                      className=" "
                      href="/lets-talk/"
                    >
                      Engage Us
                    </a>
                  </p> */}
                </div>
              </div>
            </div>
          </div>

          <div className={"col-md-2"}>
            <div className={"widget__item"}>
              <ul className={"links"}>
                <h4>LEARN MORE</h4>
                <ul>
                  {/* <li>
                        <Link to="/solutions/people" title={"People"}>
                          For People
                        </Link>
                      </li> */}
                  {/* <li>
                    <Link to="/solutions/business" title={"Business"}>
                      For Business
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link to="/solutions/partners" title={"Partners"}>
                      For Partners
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/why" title={"Why"}>
                      Why?
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/howitworks" title={"How It Works"}>
                      How It Works?
                    </Link>
                  </li> */}

                  <li>
                    <Link to="/about" title={"About Us"}>
                      About
                    </Link>
                  </li>
                  {/* <li><a href={data.site.siteMetadata.blogUrl}>Blog</a></li> */}
                </ul>
              </ul>
            </div>
          </div>

          <div className={"col-md-2"}>
            <div className={"widget__item"}>
              <ul className={"links"}>
                <h4>ENGAGE</h4>
                <ul>
                  <li>
                    <Link to="/signup/" title={"Mailing List"}>
                      Sign Up
                    </Link>
                  </li>
                  <li>
                    <Link to="/lets-talk/" title={"Let's talk"}>
                    Let's talk
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/request-demo" title={"Request Demo"}>
                      Request Demo
                    </Link>
                  </li> */}
                  {/* <li><a className={"links__special"} href={data.site.siteMetadata.feedbackUrl} target={"_blank"} title={"We look forward to receiving your great feedback"}>Feedback</a></li> */}
                </ul>
              </ul>
            </div>
          </div>

          <div className={"col-md-3"}>
            <div className={"widget__item"}>
              <div className={"links"}>
                <h4>Support</h4>
                <ul>
                  <li>
                    <Link to="/contact" title={"Contact Us"}>
                      Contact
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy" title={"Privacy Policy"}>
                      Privacy
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/terms" title={"Terms Of Use"}>
                      Terms Of Use
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>

          <div className={"col-md-2 d-none"}>
            <div className={"widget__item"}>
              <div className={"social d-none"}>
                <a
                  href={data.site.siteMetadata.twitterUrl}
                  target={"_blank"}
                  title={"Twitter"}
                >
                  <img alt={"Twitter"} src={iconTwitter} />
                </a>
                <a
                  href={data.site.siteMetadata.linkedInUrl}
                  target={"_blank"}
                  title={"LinkedIn"}
                >
                  <img alt={"LinkedIn"} src={iconLinkedin} />
                </a>
                {/* <a href="https://github.com/datapolicytrust" target={"_blank"} title={"GitHub"}><img alt={"GitHub"} src={iconGitHub}/></a> */}
                {/* <a href="https://www.instagram.com/datapolicytrust.com/" target={"_blank"} title={"Instagram"}><img alt={"Instagram"} src={iconInstagram}/></a> */}
              </div>
            </div>
          </div>
        </div>
        <footer className="footer-1 bg-dark d-none">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <div id="text-5" className="widget widget_text">
                  {/* <h6 className="title">UVISION</h6><hr /> */}
                  <div className="textwidget">
                    <p>&nbsp;</p>
                    <p style={{ color: "white" }}>
                      {/* UVISION<br /> */}
                      {/* 1900 Campus Commons Drive, Suite 100, Reston, VA 20191, USA */}
                    </p>
                    <p>
                      {/* <a href="tel:+17037292305">+703.729.2305</a><br /> */}
                      <a href="mailto:engage%40u%76%69s%69on.%63o">
                        engage@theleanfounder.com
                      </a>
                      <br /> -
                      <a
                        className=" "
                        href="/engage/"
                      >
                        Engage Us
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6" />
              <div className="col-md-3 col-sm-6" />
              <div className="col-md-3 col-sm-6" />
              <div className="clear" />{" "}
            </div>
            {/* <div className="row">
        <div className="col-sm-6">
          <span className="sub">
            Copyright © 2004-2020 UVISION. UVISION TECHNOLOGIES. 
            UVISION CONSULTING.   <br /><a href="/privacy-policy/">Privacy Policy</a>              
            </span>
        </div>
        <div className="col-sm-6 text-right">
          <ul className="list-inline social-list">
          </ul>
        </div>
      </div> */}
          </div>
          <a
            className="btn btn-sm fade-half back-to-top inner-link"
            href="#top"
          >
            Top
          </a>
        </footer>
        <div className={"copyright"}>
          <p>
            Copyright 2004-{new Date().getFullYear()}{" "}
            <a
              href={data.site.siteMetadata.url}
              title={data.site.siteMetadata.title}
            >
              {data.site.siteMetadata.title}
            </a>
            . &nbsp; UVISION. UVISION TECHNOLOGIES. UVISION CONSULTING. . All
            rights reserved.
          </p>
        </div>
      </div>
    </footer>
    <Helmet />
  </>
)

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            url
            blogUrl
            appUrl
            getStartedUrl
            twitterUrl
            linkedInUrl
            feedbackUrl
            description
            metaDescription
            homeDescription
            footerDescription
          }
        }
      }
    `}
    render={data => (
      <>
        <Head />
        <Header siteTitle={data.site.siteMetadata.title} data={data} />
        <main>{children}</main>
       <Footer data={data} />
       </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

 


export const LayoutClean = ({ children }) => (
  <>  <Head />
  {/* <Header siteTitle={data.site.siteMetadata.title} data={data} /> */}
  <main>{children}</main>
 {/* <Footer data={data} /> */}
  </>
)


LayoutClean.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
